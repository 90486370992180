const initCometChat = (userId) => {
  CometChatWidget.init({
    appID: process.env.NEXT_PUBLIC_COMETCHAT_APP_ID,
    appRegion: "us",
    authKey: process.env.NEXT_PUBLIC_COMETCHAT_AUTH_KEY,
  }).then(
    (response) => {
      CometChatWidget.login({
        uid: userId,
      }).then(
        (response) => {
          CometChatWidget.launch({
            widgetID: process.env.NEXT_PUBLIC_COMETCHAT_WIDGET_ID,
            docked: "true",
            alignment: "left", // left or right
            roundedCorners: "true",
            height: "450px",
            width: "400px",
            defaultID: userId, // default UID (user) or GUID (group) to show,
            defaultType: "user", // user or group
          });
        },
        (error) => {
          console.error(error);
        }
      );
    },
    (error) => {
      console.error(error);
    }
  );
};

export default initCometChat;
