import { atom } from "jotai";

export const editContractContentAtom = atom({});

export const mapDataAtom = atom({});

export const unreadMessagesCountAtom = atom(null);

export const friendsAtom = atom([]);

export const messagesAtom = atom([]);

export const friendAtom = atom({});

export const newUserSuccessVerificationIdAtom = atom(null);

export const detailsModalContentsAtom = atom({});

export const userTypeAtom = atom();

export const newFriendsAtom = atom(false);

export const requestBarArrayAtom = atom([]);

export const cometFriendsArrayAtom = atom([]);

export const deniedFriendsAtom = atom([]);

export const openChatAtom = atom(false);
