import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { appTheme } from "src/theme/index";
import "src/theme/css/global.css";
import NavBar from "../src/components/layout/NavBar";
import { Provider as JotaiProvider } from "jotai";
import CrispWidget from "src/components/crisp/CrispWidget";
import useUser from "src/lib/utils/swr/hooks/useUser";
import Head from "next/head";
import Footer from "src/components/layout/Footer";
import "@fontsource/poppins/400.css";

process.env.NODE_ENV === "production";

const App = ({ Component, pageProps, err }) => {
  const { user } = useUser();

  return (
    <>
      <Head>
        <title>REI Link</title>

        {/* adding favicon */}
        <link rel="shortcut icon" href="/favicon.ico" />

        {/* adding address bar coloring on mobile devices/browsers */}
        <meta content="#2f4959" name="theme-color" />
        <meta content="#2f4959" name="msapplication-navbutton-color" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta content="#2f4959" name="apple-mobile-web-app-status-bar-style" />
      </Head>
      <JotaiProvider>
        <ChakraProvider theme={appTheme}>
          <CSSReset />
          <NavBar user={user} />
          <Component {...pageProps} err={err} />
          <CrispWidget user={user} />
          <Footer />
        </ChakraProvider>
      </JotaiProvider>
    </>
  );
};

export default App;
