import Crisp from "react-crisp";

const CrispWidget = ({ user }) => {
  return user?.profile?.email &&
    user?.profile?.first_name &&
    user?.profile?.last_name ? (
    <Crisp
      crispWebsiteId={process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID}
      attributes={{
        "user:email": [user?.profile?.email],
        "user:nickname": [
          `${user?.profile?.first_name} ${user?.profile?.last_name}`,
        ],
      }}
    />
  ) : (
    <Crisp crispWebsiteId={process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID} />
  );
};

export default CrispWidget;
