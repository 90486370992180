import useConnections from "src/lib/utils/swr/hooks/useConnections";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import {
  acceptFriendRequest,
  denyFriendRequest,
  addFriendsCometChatApi,
} from "src/lib/utils/api/services";
import { FiUser } from "react-icons/fi";
import DefaultTable from "src/components/layout/tables/DefaultTable";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Flex,
  Avatar,
  Button,
  useDisclosure,
  VStack,
  Text,
  Box,
} from "@chakra-ui/react";
import { useMemo } from "react";

const AltFriendsRequests = ({ user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userId = user?.profile?.public_id;

  const {
    connections,
    connectionsError,
    connectionsIsLoading,
    mutateConnections,
  } = useConnections(userId);

  const pendingFriends = connections?.filter((deal) => {
    if (
      deal.active === false &&
      deal.pending === true &&
      deal.sender !== userId &&
      deal?.deals[0]?.type_of_closing == null
    ) {
      return deal;
    }
  });

  const handleClick = async (userId, senderId, id) => {
    await acceptFriendRequest(userId, senderId);

    const changedItem = pendingFriends.find((deal) => deal.id === id);
    changedItem.active = true;
    changedItem.pending = false;
    let arrToBeChanged = connections.find((deal) => deal.id != id);

    if (arrToBeChanged == undefined) {
      arrToBeChanged = [];
    } else if (!arrToBeChanged[0]) {
      arrToBeChanged = [arrToBeChanged];
    }
    const newArr = [...arrToBeChanged, changedItem];

    mutateConnections([newArr]);
    addFriendsCometChatApi(userId, senderId);
  };
  const handleDeny = async (userId, senderId, id) => {
    await denyFriendRequest(userId, senderId);

    const changedItem = pendingFriends.find((deal) => deal.public_id === id);
    changedItem.active = false;
    changedItem.pending = false;
    let arrToBeChanged = connections.find((deal) => deal.public_id != id);

    if (arrToBeChanged == undefined) {
      arrToBeChanged = [];
    } else if (!arrToBeChanged[0]) {
      arrToBeChanged = [arrToBeChanged];
    }
    const newArr = [...arrToBeChanged, changedItem];

    mutateconnections([newArr]);
  };
  const requestData = useMemo(() => {
    return connections === undefined && pendingFriends === undefined
      ? []
      : connectionsIsLoading
      ? [
          { loading: <LoadingLine width="100%" /> },
          { loading: <LoadingLine width="100%" /> },
          { loading: <LoadingLine width="100%" /> },
        ]
      : connectionsError
      ? [{ error: true }]
      : connections && connections?.length > 0 && pendingFriends
      ? pendingFriends
      : [];
  }, [connections, connectionsError, connectionsIsLoading]);

  const requestColumns = useMemo(() =>
    pendingFriends === undefined
      ? []
      : connectionsIsLoading
      ? [
          {
            Header: "Loading",
            accessor: "loading",
          },
        ]
      : connectionsError
      ? [
          {
            Header: "Error",
            accessor: () => {
              return (
                <>
                  <Alert m="0 auto" color="orange.500" w="32px" h="32px" />
                  <br />
                  <Text size="sm">
                    Error loading friends. Please contact support.
                  </Text>
                </>
              );
            },
          },
        ]
      : connections
      ? [
          //add search by usertype
          {
            Header: "Name",
            accessor: (data) => {
              return (
                <Text>
                  {data.profiles.first_name} {data.profiles.last_name}
                </Text>
              );
            },
          },
          {
            Header: "User Type",
            accessor: "profiles.plan_id",
          },
          {
            Header: "accept",
            accessor: (friend) => {
              return (
                <FaCheck
                  onClick={() => {
                    handleClick(userId, friend.profiles.public_id, friend.id);
                  }}
                />
              );
            },
          },
          {
            Header: "deny",
            accessor: (friend) => {
              return (
                <ImCross
                  onClick={() => {
                    handleDeny(
                      userId,
                      friend.profiles.public_id,
                      friend.public_id
                    );
                  }}
                />
              );
            },
          },
        ]
      : []
  );

  return (
    <>
      {pendingFriends != null ? (
        pendingFriends?.length > 0 ? (
          <Button colorScheme="blue" onClick={onOpen}>
            <Text position="relative" fontSize="5xl" color="white">
              <FiUser />
            </Text>
            <Box className="friend-request-number">
              <Text m="auto" fontSize="md" color="white">
                {pendingFriends?.length}
              </Text>
            </Box>
          </Button>
        ) : null
      ) : null}
      <Drawer placement={"left"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent minWidth="50vw">
            <DrawerHeader borderBottomWidth="1px">Friend Requests</DrawerHeader>
            <DrawerBody>
              <DefaultTable columns={requestColumns} data={requestData} />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default AltFriendsRequests;
