import { ImCross } from "react-icons/im";
import {
  acceptFriendRequest,
  denyFriendRequest,
} from "src/lib/utils/api/services";
import { FiUser } from "react-icons/fi";
import { BiComment } from "react-icons/bi";

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Flex,
  Avatar,
  Button,
  useDisclosure,
  VStack,
  Text,
  Box,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Alert,
} from "@chakra-ui/react";
import useConnections from "src/lib/utils/swr/hooks/useConnections";
import { useEffect, useMemo, useState, useRef } from "react";
import DefaultTable from "src/components/layout/tables/DefaultTable";
import LoadingLine from "src/components/layout/LoadingLine";
import { IoIosChatbubbles, IoIosCloseCircle } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { completeDeal } from "src/lib/utils/api/services";
import ProfileModal from "src/components/pages/user/profile/ProfileModal";
import { useAtom } from "jotai";
import { openChatAtom } from "src/lib/utils/jotai/atomDeclarations";
import useUnreadMessages from "src/lib/utils/swr/hooks/useUnreadMessages";

const LinksDrawer = ({ user }) => {
  let index = -1;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userId = user?.profile?.public_id;

  const [openChat, setOpenChat] = useAtom(openChatAtom);
  const {
    connections,
    connectionsError,
    connectionsIsLoading,
    mutatecConnections,
  } = useConnections(userId);
  const [modalOpen, setModalOpen] = useState();
  const {
    unreadMessages,
    UnreadMessagesIsLoading,
    UnreadMessagesError,
    mutateUnreadMessages,
  } = useUnreadMessages(user?.profile?.public_id);

  let unreadNumber = 0;

  const filterUnreads = unreadMessages?.data?.data.map((person) => {
    if (person?.count) {
      unreadNumber = unreadNumber + person.count;
    }
  });

  const allConnections = connections?.filter((set) => {
    if (
      set.profiles.public_id != userId &&
      set?.active === true &&
      set?.pending === false
    ) {
      return set;
    }
  });
  const approvedData = useMemo(() => {
    return connections === undefined &&
      allConnections === undefined &&
      unreadMessages != null
      ? []
      : connectionsIsLoading
      ? [
          { loading: <LoadingLine width="100%" /> },
          { loading: <LoadingLine width="100%" /> },
          { loading: <LoadingLine width="100%" /> },
        ]
      : connectionsError
      ? [{ error: true }]
      : connections && connections?.length > 0 && allConnections
      ? allConnections
      : [];
  }, [connections, connectionsError, connectionsIsLoading]);

  const approvedColumns = useMemo(() =>
    allConnections === undefined && unreadMessages != null
      ? []
      : connectionsIsLoading
      ? [
          {
            Header: "Loading",
            accessor: "loading",
          },
        ]
      : connectionsError
      ? [
          {
            Header: "Error",
            accessor: () => {
              return (
                <>
                  <Alert m="0 auto" color="orange.500" w="32px" h="32px" />
                  <br />
                  <Text size="sm">
                    Error loading active contracts. Please contact support.
                  </Text>
                </>
              );
            },
          },
        ]
      : connections
      ? [
          {
            Header: "First Name",
            accessor: "profiles.first_name",
          },
          {
            Header: "Last Name",
            accessor: "profiles.last_name",
          },
          {
            Header: "User Type",
            accessor: "profiles.plan_id",
          },
          {
            Header: "open chat",
            accessor: (data) => {
              let unreads;
              unreadMessages?.data.data.forEach((user) => {
                if (user.entityId == data.profiles.public_id) {
                  console.log(user?.count);
                  unreads = user.count;
                }
              });
              console.log(unreads);
              return (
                <Box
                  position="relative"
                  minWidth="3rem"
                  display="flex"
                  direction="row"
                >
                  <Text
                    zIndex="10"
                    onClick={() => {
                      mutateUnreadMessages;
                      setOpenChat(true);
                      CometChatWidget.openOrCloseChat(true);
                      CometChatWidget.chatWithUser(data.profiles.public_id);
                      onClose();
                    }}
                    fontSize="3xl"
                  >
                    <IoIosChatbubbles />
                  </Text>
                  {unreads > 0 ? (
                    <Box
                      className="friend-request-number"
                      width="1.5rem"
                      height="1.5rem"
                      position="relative"
                      top="-1vh"
                      left="-0.8vw"
                    >
                      <Text m="auto" fontSize="md" color="white">
                        {unreads}
                      </Text>
                    </Box>
                  ) : null}
                </Box>
              );
            },
          },
          {
            Header: "View Profile",
            accessor: (data) => {
              return (
                <Box>
                  <Button>
                    <ProfileModal data={data} />
                  </Button>
                </Box>
              );
            },
          },
        ]
      : []
  );
  return (
    <>
      <Button colorScheme="blue" onClick={onOpen}>
        <Text position="relative" fontSize="5xl" color="white">
          <IoIosChatbubbles />
        </Text>
        {unreadNumber > 0 ? (
          <Box
            className="friend-request-number"
            marginBottom="6vh"
            position="relative"
          >
            <Text m="auto" fontSize="md" color="white">
              {unreadNumber}
            </Text>
          </Box>
        ) : null}
      </Button>

      <Drawer placement={"left"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent minWidth="50vw">
            <DrawerHeader borderBottomWidth="1px">Friends</DrawerHeader>
            <DrawerBody>
              <Box>
                {approvedData[0] !== undefined ? (
                  <DefaultTable
                    columns={approvedColumns}
                    data={approvedData}
                    filterPlaceholder="Search Friends"
                  />
                ) : (
                  <Text textAlign="center" fontSize="2xl">
                    No Friends
                  </Text>
                )}
              </Box>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default LinksDrawer;
