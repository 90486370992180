import { default as NextImage } from "next/image";
import { Flex, Text, Box } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Flex
      w="100%"
      h="10vh"
      justify="center"
      alignItems="center"
      bgColor="darkBlue"
    >
      <Box w={["15%", null, "12%", "10%", "5vw"]} h="8vh" mr="2vw">
        <Box position="relative" w="100%" h="100%">
          <NextImage
            src="/images/SEO-for-REI-logo.png"
            layout="fill"
            objectFit="cover"
            priority={true}
          />
        </Box>
      </Box>
      <Text size="sm" color="white">
        © 2021 | SEO for Real Estate Investors LLC
      </Text>
    </Flex>
  );
};

export default Footer;
