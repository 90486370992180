import { Button, Box } from "@chakra-ui/react";
import { unreadMessagesCountAtom } from "src/lib/utils/jotai/atomDeclarations";
import { useAtom } from "jotai";
import Link from "next/link";

const Notifications = ({ onClose, user, router, isMobileNav }) => {
  const [unreadMessagesCount, setUnreadMessagesCount] = useAtom(
    unreadMessagesCountAtom
  );

  return router.pathname === "/user/chat" || user?.profile?.cancelled ? (
    <Button
      w={isMobileNav ? "60%" : "auto"}
      variant="formPrimary"
      isDisabled
      onClick={() => {
        isMobileNav && onClose();
      }}
    >
      Inbox
    </Button>
  ) : (
    <Link href="/user/deals">
      <a style={{ width: isMobileNav ? "60%" : "auto" }}>
        <Button
          w={isMobileNav ? "100%" : "auto"}
          variant="formPrimary"
          onClick={() => {
            setUnreadMessagesCount(0);
            isMobileNav && onClose();
          }}
        >
          Deals
          {unreadMessagesCount && unreadMessagesCount > 0 ? (
            <Box
              bgColor="blue.base"
              p="1px 3px"
              fontSize="12px"
              color="white"
              position="absolute"
              top={-3}
              right={-2}
              borderRadius="5px"
            >
              {unreadMessagesCount}
            </Box>
          ) : (
            <></>
          )}
        </Button>
      </a>
    </Link>
  );
};
export default Notifications;
