import {
  Flex,
  Spacer,
  Button,
  Box,
  VStack,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useMediaQuery, useDisclosure } from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import useUser from "src/lib/utils/swr/hooks/useUser";
import useUnreadMessages from "src/lib/utils/swr/hooks/useUnreadMessages";
import { signOut } from "src/lib/utils/api/services";
import { useRouter } from "next/router";
import Notifications from "src/components/cometchat/Notifications";
import LoadingLine from "src/components/layout/LoadingLine";
import { useEffect, useState, useRef } from "react";
import {
  ChakraChatBubbles,
  ChakraHouse,
  ChakraSettings,
  ChakraKey,
  ChakraMenu,
  ChakraHandshake,
  ChakraWindowClose,
  ChakraNewspaper,
} from "src/lib/utils/icons";
import FriendRequests from "src/components/cometchat/links/FriendRequests";
import LinksDrawer from "src/components/cometchat/links/LinksDrawer";
import { IoIosCloseCircle } from "react-icons/io";
import { useScript } from "src/lib/utils/hooks";
import initCometChat from "src/lib/utils/cometchat/init";
import { useAtom } from "jotai";
import { openChatAtom } from "src/lib/utils/jotai/atomDeclarations";

// TODO: ensure the links/routing for every navbar iteration are correct
const NavBar = () => {
  const { user, isUserLoading, mutateUser } = useUser();
  const [openChat, setOpenChat] = useAtom(openChatAtom);
  const scriptStatus = useScript(
    "https://widget-js.cometchat.io/v2/cometchatwidget.js"
  );
  const {
    UnreadMessages,
    UnreadMessagesIsLoading,
    UnreadMessagesError,
  } = useUnreadMessages(user?.profile?.public_id);
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      user &&
      user?.isLoggedIn &&
      scriptStatus === "ready"
    ) {
      setTimeout(
        () => initCometChat(user?.profile?.public_id?.toString()),
        500
      );
    }
  }, [scriptStatus, user]);
  const router = useRouter();

  useEffect(() => {
    if (UnreadMessages) {
      UnreadMessages.map((message) => {});
    }
  }, [UnreadMessages]);

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();
  const drawerButtonRef = useRef();

  const [isNavMinimized, setIsNavMinimized] = useState(false);

  const [isMobile] = useMediaQuery("( max-width: 420px )");

  const transformNav = () => {
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      if (!isNavMinimized) {
        setIsNavMinimized(true);
      }
    } else {
      setIsNavMinimized(false);
    }
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("scroll", transformNav, { passive: true });
    }

    return () => {
      window.removeEventListener("scroll", transformNav, { passive: true });
    };
  }, []);

  return (
    <Flex
      id="navbar"
      bg="darkBlue"
      // bg={isNavMinimized ? "rgba(47, 73, 89, 0.9)" : "rgba(47, 73, 89, 1)"}
      w="100%"
      h={
        isNavMinimized && isMobile ? "8.5vh" : isNavMinimized ? "7.5vh" : "10vh"
      }
      boxShadow={
        isNavMinimized && !isMobile ? "rgb(3 27 78 / 10%) 0px 2px 4px" : "none"
      }
      zIndex="101"
      position="fixed"
      transition="0.4s all"
      alignItems="center"
    >
      {openChat ? (
        <Button
          className="close-chat-button"
          onClick={() => {
            CometChatWidget.openOrCloseChat(false);
            setOpenChat(false);
          }}
        >
          <Text color="red" fontSize="6xl">
            <IoIosCloseCircle />
          </Text>
        </Button>
      ) : null}
      <Flex
        w={["50%", null, "45%", "45%", "25.5%"]}
        justify="space-between"
        align="center"
        className="next-image-container"
        ml="1rem"
      >
        <Box
          transition="0.4s all"
          w={isNavMinimized ? "109px" : "133px"}
          h={isNavMinimized ? "40px" : "49px"}
          position="relative"
        >
          <Link href="/">
            <a
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Image
                src="/images/the-rei-link-logo-light-white.png"
                layout="fill"
                objectFit="cover"
                priority={true}
                alt="The REI Link Logo"
              />
            </a>
          </Link>
        </Box>
      </Flex>
      <Spacer />
      <Flex display={["flex", null, "none"]} justify="flex-end">
        <Button>
          <ChakraMenu
            onClick={() => onDrawerOpen()}
            color="white"
            w={isNavMinimized ? "28px" : "32px"}
            h={isNavMinimized ? "28px" : "32px"}
            transition="0.4s all"
          />
        </Button>
        <Drawer
          isOpen={isDrawerOpen}
          placement="left"
          onClose={onDrawerClose}
          finalFocusRef={drawerButtonRef}
        >
          <DrawerOverlay>
            <DrawerContent borderRadius="0px 10px 10px 0px">
              <DrawerCloseButton
                as={ChakraWindowClose}
                cursor="pointer"
                color="navyBlue"
                right={0}
                left="0.75rem"
              />
              <DrawerHeader />
              <DrawerBody>
                {user?.isLoggedIn === false || !user ? (
                  <VStack
                    w="100%"
                    h="100%"
                    justify="space-evenly"
                    align="center"
                    spacing={25}
                  >
                    <Link href="/">
                      <a
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => onDrawerClose()}
                      >
                        <Image
                          src="/images/the-rei-link-logo-dark-blue.png"
                          width={200}
                          height={75.28}
                          priority={true}
                          alt="The REI Link Logo"
                        />
                      </a>
                    </Link>
                    <HStack>
                      <ChakraNewspaper w="50%" h="50%" color="navyBlue" />
                      <Link href="/blog">
                        <a style={{ width: "50%" }}>
                          <Button
                            w="100%"
                            variant="formPrimary"
                            onClick={() => onDrawerClose()}
                          >
                            Blog
                          </Button>
                        </a>
                      </Link>
                    </HStack>
                    <HStack>
                      <ChakraKey w="50%" h="50%" color="navyBlue" />
                      <Link href="/auth/login">
                        <a style={{ width: "50%" }}>
                          <Button
                            w="100%"
                            variant="formPrimary"
                            onClick={() => onDrawerClose()}
                          >
                            Log In
                          </Button>
                        </a>
                      </Link>
                    </HStack>
                    <HStack>
                      <ChakraHandshake w="50%" h="50%" color="navyBlue" />
                      <Link href="/pricing">
                        <a style={{ width: "50%" }}>
                          <Button
                            w="100%"
                            variant="formPrimary"
                            onClick={() => onDrawerClose()}
                          >
                            Sign Up
                          </Button>
                        </a>
                      </Link>
                    </HStack>
                  </VStack>
                ) : (
                  <VStack
                    w="100%"
                    h="100%"
                    justify="space-evenly"
                    align="center"
                  >
                    <Link href="/">
                      <a
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => onDrawerClose()}
                      >
                        <Image
                          src="/images/the-rei-link-logo-dark-blue.png"
                          width={200}
                          height={75.28}
                          priority={true}
                          alt="The REI Link Logo"
                        />
                      </a>
                    </Link>
                    {isUserLoading ? (
                      <>
                        <LoadingLine width="100%" />
                        <LoadingLine width="100%" />
                      </>
                    ) : (
                      <>
                        <HStack>
                          <Notifications
                            onClose={onDrawerClose}
                            isMobileNav
                            user={user}
                            router={router}
                          />
                          <ChakraChatBubbles w="40%" h="40%" color="navyBlue" />
                        </HStack>
                        {user?.role?.includes("wholesaler") &&
                          !user?.profile?.cancelled && (
                            <HStack>
                              <Link href="/user/dashboard/wholesaler">
                                <a
                                  style={{
                                    width: "60%",
                                    cursor:
                                      router?.pathname ===
                                      "/user/dashboard/wholesaler"
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  <Button
                                    variant="formPrimary"
                                    onClick={() => onDrawerClose()}
                                    w="100%"
                                  >
                                    Wholesaler
                                    <br />
                                    Dashboard
                                  </Button>
                                </a>
                              </Link>
                              <ChakraHouse w="40%" h="40%" color="navyBlue" />
                            </HStack>
                          )}
                        {user?.role?.includes("cash-buyer") &&
                          !user?.profile?.cancelled && (
                            <HStack>
                              <Link href="/user/dashboard/cash-buyer">
                                <a
                                  style={{
                                    width: "60%",
                                    cursor:
                                      router?.pathname ===
                                      "/user/dashboard/cash-buyer"
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  <Button
                                    variant="formPrimary"
                                    onClick={() => onDrawerClose()}
                                    w="100%"
                                  >
                                    Cash Buyer
                                  </Button>
                                </a>
                              </Link>
                              <ChakraHandshake
                                w="40%"
                                h="40%"
                                color="navyBlue"
                              />
                            </HStack>
                          )}
                        <HStack>
                          <Link href="/user/dashboard">
                            <a
                              style={{
                                width: "60%",
                                cursor:
                                  router?.pathname === "/user/dashboard"
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            >
                              <Button
                                variant="formPrimary"
                                onClick={() => onDrawerClose()}
                                w="100%"
                              >
                                Settings
                              </Button>
                            </a>
                          </Link>
                          <ChakraSettings w="40%" h="40%" color="navyBlue" />
                        </HStack>
                      </>
                    )}
                  </VStack>
                )}
              </DrawerBody>
              <DrawerFooter>
                {user?.isLoggedIn === false || !user ? (
                  <></>
                ) : (
                  <HStack mr="auto">
                    <Button
                      variant="formPrimary"
                      onClick={async () => {
                        setOpenChat(false);

                        onDrawerClose();

                        await signOut();

                        router.push("/");

                        mutateUser({ isLoggedIn: false });

                        location.reload();
                      }}
                    >
                      Sign Out
                    </Button>
                    <Text fontSize="32px">👋</Text>
                  </HStack>
                )}
              </DrawerFooter>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Flex>
      {user?.isLoggedIn === false || !user ? (
        <Flex
          w={
            isNavMinimized
              ? ["100%", null, "45%", "43%", "23%"]
              : ["100%", null, "48%", "48%", "25%"]
          }
          justify="space-around"
          align="center"
          mr={["5px", "15px"]}
          transition="0.4s all"
          display={["none", null, "flex"]}
        >
          <Link href="/blog">
            <a>
              <Button variant="navSecondary">Blog</Button>
            </a>
          </Link>
          <Link href="/auth/login">
            <a>
              <Button variant="navSecondary">Log In</Button>
            </a>
          </Link>
          <Link href="/pricing">
            <a>
              <Button variant="formPrimary">Sign Up</Button>
            </a>
          </Link>
        </Flex>
      ) : (
        <Flex
          w={
            (user?.role?.includes("wholesaler") ||
              user?.role?.includes("fsbo")) &&
            isNavMinimized
              ? ["100%", null, "65%", "55%", "42.5%"]
              : isNavMinimized
              ? ["100%", null, "65%", "55%", "35%"]
              : user?.role?.includes("wholesaler") ||
                user?.role?.includes("fsbo")
              ? ["100%", null, "70%", "60%", "45%"]
              : ["100%", null, "65%", "55%", "37.5%"]
          }
          justify="space-around"
          align="center"
          transition="0.4s all"
          display={["none", null, "flex"]}
        >
          {isUserLoading ? (
            <LoadingLine width="100%" />
          ) : (
            <>
              <FriendRequests marginLeft="1rem" user={user} />
              <LinksDrawer marginLeft="1rem" user={user} />
              <Link href="/user/search">
                <a>
                  <Button variant="formPrimary">Search</Button>
                </a>
              </Link>
              {(user?.role?.includes("wholesaler") ||
                user?.role?.includes("fsbo")) && (
                <Link href="/user/dashboard">
                  <a>
                    <Button variant="formPrimary">
                      {user?.role?.includes("wholesaler")
                        ? "Contracts"
                        : "Listings"}
                    </Button>
                  </a>
                </Link>
              )}
              <Link href="/user/profile">
                <a>
                  <Button variant="formPrimary">Profile</Button>
                </a>
              </Link>
              <Button
                onClick={async () => {
                  setOpenChat(false);

                  await signOut();

                  router.push("/");

                  mutateUser({ isLoggedIn: false });

                  location.reload();
                }}
                variant="navSecondary"
              >
                Sign Out
              </Button>
              <Link href="/user/settings">
                <a>
                  <ChakraSettings
                    w="32px"
                    h="32px"
                    color="white"
                    transition="0.25s all"
                    _hover={{
                      color: "lightGrey",
                    }}
                  />
                </a>
              </Link>
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default NavBar;
